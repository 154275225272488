<template>
  <h1>Logging out</h1>
  <p>Bitte warte kurz...</p>
</template>

<script>
import { handleAuth } from "@/mixins/handleAuth";
import router from "@/router";
export default {
  mixins: [handleAuth],
  mounted() {
    this.logout();
    router.push("/");
  },
};
</script>
